const { isCountryInContinent, OCEANIA } = require("../countries/countries-service");
const { USE_OLD_STORAGE_SETTINGS, WEBSITE_AB_TEST_DEBUG_COOKIE_NAME } = require("../../constants/cookies");
const { getAbTestVariantNew } = require("../new-ab-tests/new-ab-tests-service");
const { doesCountryRequireStrictCookiePolicy } = require("../../server/services/aws-service/browser-service");
const { isRunningInClient } = require("../dom-utils-service");
const { SKIP_SIGN_UP_STEP_IN_PRICING_PAGE_AB_TEST, EXIT_POP_UP_SURVEY_AB_TEST, REVIVE_EXIT_POPUP_AB_TEST, PERFORMANCE_STATIC_PAGE_AB_TEST, BIGGER_STORAGE_IN_PRICING_PAGE_FEATURES, DESKTOP_WITH_ENTERPRISE_IN_SUBMENU_TEST, PRICING_ENTERPRISE_CONTACT_SALES_AB_TEST, MOBILE_STICKY_SIGNUP_AB_TEST, WELCOME_BACK_PAGE_TEST, KEEP_REGION_TEST, CONTACT_SALES_TYPEFORM_EMEA, CONTACT_SALES_TYPEFORM_ANZ, HEADER_NEW_PRODUCTS_OFFERING_TEST, UNLIMITED_TIME_FREE_PLAN_CTA_TEXT_TEST, SHOW_SUCCESS_STORIES_IN_HEADER_TEST, MOBILE_SOFT_SIGNUP_TEST, TYPEFORM_FIRST_SCREEN_MULTIPLE_FIELDS_TEST, STUDIO_IN_HEADER_TEST, ENHANCED_PRODUCTS_IN_HEADER_TEST, WM_HIGH_INTENT_EXPERIENCE, CONTACT_SALES_FOOTER_TEST, HEADER_REVAMP_TEST, DACH_LPS_GET_STARTED_BUTTON_TEXT_TEST, BOOK_A_MEETING_EMAIL_TEST } = require("../../constants/new-ab-tests");
const shouldGetSkipSignUpStepInPricingAbTest = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, SKIP_SIGN_UP_STEP_IN_PRICING_PAGE_AB_TEST, sendBigBrainEvent) === "new_with_skip";
};
const shouldSeeExitPopupSurveyAbTest = (abTests = [], sendBigBrainEvent)=>{
    return getAbTestVariantNew(abTests, EXIT_POP_UP_SURVEY_AB_TEST, sendBigBrainEvent) === "new_with_survey";
};
const shouldSeeReviveExitPopupAbTest = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, REVIVE_EXIT_POPUP_AB_TEST.test_name, sendBigBrainEvent) === REVIVE_EXIT_POPUP_AB_TEST.new_variation_name;
};
const shouldSeeStaticPagePerformanceAbTest = (abTests = [], cloudfrontViewerCountry = null, sendBigBrainEvent = false)=>{
    if (doesCountryRequireStrictCookiePolicy(cloudfrontViewerCountry)) return false;
    return getAbTestVariantNew(abTests, PERFORMANCE_STATIC_PAGE_AB_TEST.test_name, sendBigBrainEvent) === PERFORMANCE_STATIC_PAGE_AB_TEST.new_variation_name;
};
const shouldShowBiggerStorageInPricingFeatures = (abTests = [], cookies = {}, sendBigBrainEvent = false)=>{
    if (typeof cookies[USE_OLD_STORAGE_SETTINGS] !== "undefined") {
        return cookies[USE_OLD_STORAGE_SETTINGS] !== "true";
    }
    return getAbTestVariantNew(abTests, BIGGER_STORAGE_IN_PRICING_PAGE_FEATURES.test_name, sendBigBrainEvent) === BIGGER_STORAGE_IN_PRICING_PAGE_FEATURES.new_variation_name;
};
const shouldSeeDesktopWithEnterpriseMenuAbTest = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, DESKTOP_WITH_ENTERPRISE_IN_SUBMENU_TEST.test_name, sendBigBrainEvent) === DESKTOP_WITH_ENTERPRISE_IN_SUBMENU_TEST.new_variation_name;
};
const shouldGetEnterpriseContactUsPageAbTest = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, PRICING_ENTERPRISE_CONTACT_SALES_AB_TEST.test_name, sendBigBrainEvent) === PRICING_ENTERPRISE_CONTACT_SALES_AB_TEST.new_variation_name;
};
const shouldGetMobileStickySignupAbTest = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, MOBILE_STICKY_SIGNUP_AB_TEST.test_name, sendBigBrainEvent) === MOBILE_STICKY_SIGNUP_AB_TEST.new_variation_name;
};
const shouldGetWelcomeBackPageTest = (abTests = [], persistantCookies = {}, sendBigBrainEvent = false, extraBigBrainParams = {})=>{
    if (persistantCookies[WEBSITE_AB_TEST_DEBUG_COOKIE_NAME] === WELCOME_BACK_PAGE_TEST.test_name) return true;
    return getAbTestVariantNew(abTests, WELCOME_BACK_PAGE_TEST.test_name, sendBigBrainEvent, false, extraBigBrainParams) === WELCOME_BACK_PAGE_TEST.new_variation_name;
};
const shouldGetKeepRegionTest = (abTests = [], cloudfrontViewerCountry = null, sendBigBrainEvent = false)=>{
    try {
        var _localStorage;
        if (isRunningInClient() && ((_localStorage = localStorage) === null || _localStorage === void 0 ? void 0 : _localStorage.getItem("is_in_keep_region_test")) === "true") {
            return true;
        }
        if (isCountryInContinent(cloudfrontViewerCountry, OCEANIA)) {
            // only people from Oceanic countries should enter the test
            return getAbTestVariantNew(abTests, KEEP_REGION_TEST.test_name, sendBigBrainEvent) === KEEP_REGION_TEST.new_variation_name;
        }
    } catch (error) {
    // nothing to do - just done to protect from crashes
    }
    return false;
};
const shouldGetContactSalesTypeformEmeaTest = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, CONTACT_SALES_TYPEFORM_EMEA.test_name, sendBigBrainEvent) === CONTACT_SALES_TYPEFORM_EMEA.new_variation_name;
};
const shouldGetContactSalesTypeformAnzTest = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, CONTACT_SALES_TYPEFORM_ANZ.test_name, sendBigBrainEvent) === CONTACT_SALES_TYPEFORM_ANZ.new_variation_name;
};
const shouldHaveHeaderWithNewProductsOfferingTest = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, HEADER_NEW_PRODUCTS_OFFERING_TEST.test_name, sendBigBrainEvent) === HEADER_NEW_PRODUCTS_OFFERING_TEST.new_variation_name;
};
const shouldGetUnlimitedTimeCTATextTest = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, UNLIMITED_TIME_FREE_PLAN_CTA_TEXT_TEST.test_name, sendBigBrainEvent) === UNLIMITED_TIME_FREE_PLAN_CTA_TEXT_TEST.new_variation_name;
};
const shouldShowSuccessStoriesInHeaderTest = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, SHOW_SUCCESS_STORIES_IN_HEADER_TEST.test_name, sendBigBrainEvent) === SHOW_SUCCESS_STORIES_IN_HEADER_TEST.new_variation_name;
};
const shouldGetMobileSoftSignupTest = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, MOBILE_SOFT_SIGNUP_TEST.test_name, sendBigBrainEvent) === MOBILE_SOFT_SIGNUP_TEST.new_variation_name;
};
const shouldUseNewTypeformVariant = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, TYPEFORM_FIRST_SCREEN_MULTIPLE_FIELDS_TEST.test_name, sendBigBrainEvent) === TYPEFORM_FIRST_SCREEN_MULTIPLE_FIELDS_TEST.new_variation_name;
};
const shouldHaveStudioProductInHeaderTest = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, STUDIO_IN_HEADER_TEST.test_name, sendBigBrainEvent) === STUDIO_IN_HEADER_TEST.new_variation_name;
};
const shouldHaveEnhancedProductsInHeaderTest = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, ENHANCED_PRODUCTS_IN_HEADER_TEST.test_name, sendBigBrainEvent) === ENHANCED_PRODUCTS_IN_HEADER_TEST.new_variation_name;
};
const shouldGetWMHighIntentExperience = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, WM_HIGH_INTENT_EXPERIENCE.test_name, sendBigBrainEvent) === WM_HIGH_INTENT_EXPERIENCE.new_variation_name;
};
const shouldGetContactSalesInTheFooter = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, CONTACT_SALES_FOOTER_TEST.test_name, sendBigBrainEvent) === CONTACT_SALES_FOOTER_TEST.new_variation_name;
};
const shouldGetHeaderRevamp = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, HEADER_REVAMP_TEST.test_name, sendBigBrainEvent) === HEADER_REVAMP_TEST.new_variation_name;
};
const shouldUseNewDachGetStartedButtonTextTest = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, DACH_LPS_GET_STARTED_BUTTON_TEXT_TEST.test_name, sendBigBrainEvent) === DACH_LPS_GET_STARTED_BUTTON_TEXT_TEST.new_variation_name;
};
const shouldSendBookAMeetingEmail = (abTests = [], sendBigBrainEvent = false)=>{
    return getAbTestVariantNew(abTests, BOOK_A_MEETING_EMAIL_TEST.test_name, sendBigBrainEvent) === BOOK_A_MEETING_EMAIL_TEST.new_variation_name;
};
module.exports = {
    shouldGetSkipSignUpStepInPricingAbTest,
    shouldSeeExitPopupSurveyAbTest,
    shouldSeeReviveExitPopupAbTest,
    shouldSeeStaticPagePerformanceAbTest,
    shouldShowBiggerStorageInPricingFeatures,
    shouldSeeDesktopWithEnterpriseMenuAbTest,
    shouldGetEnterpriseContactUsPageAbTest,
    shouldGetMobileStickySignupAbTest,
    shouldGetWelcomeBackPageTest,
    shouldGetKeepRegionTest,
    shouldGetContactSalesTypeformEmeaTest,
    shouldGetContactSalesTypeformAnzTest,
    shouldHaveHeaderWithNewProductsOfferingTest,
    shouldGetUnlimitedTimeCTATextTest,
    shouldShowSuccessStoriesInHeaderTest,
    shouldGetMobileSoftSignupTest,
    shouldUseNewTypeformVariant,
    shouldHaveStudioProductInHeaderTest,
    shouldHaveEnhancedProductsInHeaderTest,
    shouldGetWMHighIntentExperience,
    shouldGetContactSalesInTheFooter,
    shouldGetHeaderRevamp,
    shouldUseNewDachGetStartedButtonTextTest,
    shouldSendBookAMeetingEmail
};
